import { useRef } from 'react'
import  isEqual  from 'lodash/isEqual'

const usePrevious = <T>(value: T): T | undefined => {
  const ref = useRef<{ value: T; prev: T | undefined }>({
    value,
    prev: undefined,
  })

  const current = ref.current.value

  if (!isEqual(value, current)) {
    ref.current = {
      value,
      prev: current,
    }
  }

  return ref.current.prev
}

export default usePrevious
