import { RefObject, useCallback, useEffect, useRef, useState } from 'react'

import usePrevious from '@promova/utils/customHooks/usePrevious'
import { sleep } from '@promova/utils/helpers'

export type UseFitTextConfig = {
  initialFontSize?: number
  minFontSize?: number
  step?: number
}

const useFitText = <C extends {}, T extends {}>(
  config: UseFitTextConfig
): {
  fontSize: number
  containerRef: RefObject<C | HTMLDivElement>
  textRef: RefObject<T | HTMLDivElement>
} => {
  const { initialFontSize = 16, minFontSize = 8, step = 1 } = config

  const [fontSize, setFontSize] = useState(initialFontSize)

  const prevFontSize = usePrevious(fontSize)

  const containerRef = useRef<C | HTMLDivElement>(null)
  const textRef = useRef<T | HTMLDivElement>(null)

  const [rendered, setRendered] = useState(false)

  const changeTextSize = useCallback(async () => {
    const text = textRef.current
    const container = containerRef.current

    if (!text || !container || !rendered) return

    await sleep(100)

    if (
      !(
        'scrollWidth' in text &&
        'scrollHeight' in text &&
        'offsetWidth' in container &&
        'offsetHeight' in container
      )
    ) {
      return
    }
    const containerComputedStyle = window.getComputedStyle(container, null)

    const widthPaddings =
      parseFloat(containerComputedStyle.paddingLeft) +
      parseFloat(containerComputedStyle.paddingRight)
    const heightPaddings =
      parseFloat(containerComputedStyle.paddingTop) +
      parseFloat(containerComputedStyle.paddingBottom)

    const containerWidth = container.clientWidth - widthPaddings
    const containerHeight = container.clientHeight - heightPaddings

    // When child and parent element has same height,
    // scrollHeight is bigger than offsetHeight by 10% of font size.
    // To prevent font size changing, we need to subtract 10% of font size from scrollHeight.
    const heightDiff = Math.ceil(fontSize * 0.1)

    const textWidth = text.scrollWidth
    const textHeight = text.scrollHeight - heightDiff

    if (textHeight > containerHeight || textWidth > containerWidth) {
      setFontSize((prevSize) => {
        const newSize = prevSize - step

        return newSize > minFontSize ? newSize : minFontSize
      })
    }
  }, [fontSize, minFontSize, rendered, step])

  useEffect(() => {
    const onResize = () => {
      setFontSize(initialFontSize)

      changeTextSize()
    }

    window.addEventListener('resize', onResize)

    return () => {
      window.removeEventListener('resize', onResize)
    }
  }, [changeTextSize, initialFontSize])

  useEffect(() => {
    if (prevFontSize === fontSize) return

    changeTextSize()
  }, [changeTextSize, fontSize, prevFontSize])

  useEffect(() => {
    setRendered(true)
  }, [])

  return { fontSize, containerRef, textRef }
}

export default useFitText
