const icons = {
  user_group: 'users-groupe.svg',
  user: 'user.svg',
  book_reader: 'book-reader.svg',
  book: 'book.svg',
  comments: 'comments.svg',
  user_arrows: 'user-arrows.svg',
  user_square: 'user-square.svg',
  user_desktop: 'user-desktop.svg',
  search: 'search.svg',
  dollar: 'dollar.svg',
  thumbs_up: 'thumbs-up.svg',
  thumbs_down: 'thumbs-down.svg',
  wallet: 'wallet.svg',
  redo: 'redo.svg',
  repeat: 'repeat.svg',
  volume: 'volume.svg',
  volume_solid: 'volume_solid.svg',
  volume_solid_v2: 'volume_solid_v2.svg',
  volume_down: 'volume-down.svg',
  volume_down_solid: 'volume_down_solid.svg',
  volume_mute: 'volume-mute.svg',
  volume_mute_solid: 'volume_mute_solid.svg',
  volume_off: 'volume-off.svg',
  volume_off_solid: 'volume_off_solid.svg',
  volume_up: 'volume-up.svg',
  volume_up_solid: 'volume_up_solid.svg',
  play: 'play.svg',
  play_solid: 'play_solid.svg',
  pause: 'pause.svg',
  pause_solid: 'pause_solid.svg',
  backward: 'backward.svg',
  forward: 'forward.svg',
  locked: 'loсked.svg',
  unlock: 'unlock.svg',
  arrow_down: 'arrow-down.svg',
  arrow_up: 'arrow-up.svg',
  arrow_right: 'arrow-right.svg',
  angle_left: 'angle-left.svg',
  angle_right: 'angle-right.svg',
  angle_up: 'angle-up.svg',
  angle_down: 'angle-down.svg',
  info_circle: 'info-circle.svg',
  info: 'info.svg',
  upload: 'upload.svg',
  stopwatch: 'stopwatch.svg',
  microphone: 'microphone.svg',
  sync: 'sync.svg',
  check_circle: 'check-circle.svg',
  check_circle_solid: 'check-circle-solid.svg',
  check: 'check.svg',
  star: 'star.svg',
  lock_solid: 'lock-solid.svg',
  close: 'close.svg',
  property_false: 'property_false.svg',
  property_true: 'property_true.svg',
  property_true_green: 'property_positive_green.svg',
  property_select: 'property_select.svg',
  loader: 'loader.svg',
  save: 'save.svg',
  calendar: 'calendar.svg',
  open_eye: 'open_eye.svg',
  closed_eye: 'closed_eye.svg',
  plus_circle: 'plus-circle.svg',
  battery_empty: 'battery_empty.svg',
  battery_1: 'battery_1.svg',
  battery_2: 'battery_2.svg',
  battery_full: 'battery_full.svg',
  lightning_solid: 'lightning_solid.svg',
  lightning: 'lightning.svg',
  office_bag: 'office_bag.svg',
  flight: 'flight.svg',
  apple: 'apple.svg',
  wifi_slash: 'wifi-slash.svg',
  android: 'android.svg',
  cross_circle: 'cross-circle.svg',
  setting: 'setting.svg',
  cross_circle_solid: 'cross-circle-solid.svg',
  info_circle_solid: 'info-circle-solid.svg',
  trash_bin: 'trash_bin.svg',
  microphone_solid: 'microphone-solid.svg',
  stop_solid: 'stop-solid.svg',
  retry: 'retry.svg',
  shield_check: 'shield_check.svg',
  desktop: 'desktop.svg',
  megaphone: 'megaphone.svg',
  book_open: 'book_open.svg',
  pen: 'pencil.svg',
  question_circle: 'question-circle.svg',
  mobile: 'mobile.svg',
  ghost: 'ghost.svg',
  radio: 'radio.svg',
  snowman: 'snowman.svg',
  filter: 'filter.svg',
  fire: 'fire.svg',
  dollar_circle: 'dollar-circle.svg',
  clock: 'clock.svg',
  envelope: 'envelope.svg',
  copy: 'copy.svg',
  fullscreen_in: 'fullscreen-in.svg',
  fullscreen_out: 'fullscreen-out.svg',
  google: 'Google_logo_v1.svg',
  send_message: 'send_message.svg',
}

const ICONS = new Proxy(icons, {
  get(target, prop: keyof typeof icons) {
    return prop in target ? target[prop] : null
  },
})

export default ICONS
